import { Action } from "@ngrx/store";
import { ITab, JumpMenuItems } from "core/models";

export const GET_TABS_FOR_USER = "[DMS] Get Tabs For User";
export const GET_TABS_FOR_USER_SUCCESS = "[DMS] Get Tabs For User Success";
export const GET_TABS_FOR_USER_FAILURE = "[DMS] Get Tabs For User Failure";
export const ADD_TAB = "[DMS] Add Tab";
export const ADD_MANY_TABS = "[DMS] Add Many Tabs";
export const ADD_TAB_SUCCESS = "[DMS] Add Tab Success";
export const REMOVE_TAB = "[DMS] Remove Tab";
export const REMOVE_TAB_SUCCESS = "[DMS] Remove Tab Success";
export const CHANGE_ACTIVE_TAB_AFTER_UPDATE_TABS = "[DMS] Change Active Tab After Update Tabs";
export const SET_SELECTED_TAB = "[DMS] Set Selected Tab";
export const UPDATE_JUMP_MENU_SELECTED_ITEM_IN_TAB =
  "[DMS] Update Jump Menu Selected Item In Tab";
export const UPDATE_JUMP_MENU_SELECTED_ITEM_IN_TAB_AND_SET_ACTIVE =
  "[DMS] Update Jump Menu Selected Item In Tab and Set Active";

export const UPDATE_IS_DIRTY_IN_TAB = "[DMS] Update Is Dirty In Tab";

export class GetTabsForUser implements Action {
  readonly type = GET_TABS_FOR_USER;
}
export class GetTabsForUserSuccess implements Action {
  readonly type = GET_TABS_FOR_USER_SUCCESS;
  constructor(public payload: any) { }
}
export class GetTabsForUserFailure implements Action {
 readonly type = GET_TABS_FOR_USER_FAILURE;
}

export class AddTab implements Action {
  readonly type = ADD_TAB;
  constructor(public payload: ITab) {
    if (!payload.id) {
      payload.id = payload.type;
    }
    if (!payload.metadata) {
      payload.metadata = {};
    }
  }
}
export class AddManyTabs implements Action {
  readonly type = ADD_MANY_TABS;
  constructor(public payload: {tabs: ITab[], activeTabId:string}) {}
}
export class AddTabSuccess implements Action {
  readonly type = ADD_TAB_SUCCESS;
  constructor(public payload: ITab) {
    if (!payload.id) {
      payload.id = payload.type;
    }
  }
}

export class RemoveTab implements Action {
  readonly type = REMOVE_TAB;
  constructor(public payload: string) { }
}
export class RemoveTabSuccess implements Action {
  readonly type = REMOVE_TAB_SUCCESS;
  constructor(public payload: {id:string, selectedTab:string}) { }
}

export class ChangeActiveTabAfterUpdateTabs implements Action {
  readonly type= CHANGE_ACTIVE_TAB_AFTER_UPDATE_TABS;
  constructor(public payload :string){}
}

export class SetSelectedTab implements Action {
  readonly type = SET_SELECTED_TAB;
  constructor(public payload: ITab) { }
}

export class UpdateJumpMenuSelectedItemInTab implements Action {
  readonly type = UPDATE_JUMP_MENU_SELECTED_ITEM_IN_TAB;
  constructor(
    public payload: {
      id: string;
      jumpMenuSelectedItem?: JumpMenuItems;
      jumpTabSelectedItem?: JumpMenuItems;
    }
  ) { }
}
export class UpdateJumpMenuSelectedItemInTabAndActiveTab implements Action {
  readonly type = UPDATE_JUMP_MENU_SELECTED_ITEM_IN_TAB_AND_SET_ACTIVE;
  constructor(
    public payload: {
      id: string;
      jumpMenuSelectedItem: JumpMenuItems;
      jumpTabSelectedItem: JumpMenuItems;
    }
  ) { }
}
export class UpdateIsDirtyInTab implements Action {
  readonly type = UPDATE_IS_DIRTY_IN_TAB;
  constructor(
    public payload: {
      id: string;
      isDirty: boolean;
    }
  ) { }
}

export type TabActions =
  | GetTabsForUser
  | GetTabsForUserSuccess
  | GetTabsForUserFailure
  | AddManyTabs
  | AddTab
  | AddTabSuccess
  | RemoveTab
  | RemoveTabSuccess
  | SetSelectedTab
  | ChangeActiveTabAfterUpdateTabs
  | UpdateJumpMenuSelectedItemInTab
  | UpdateJumpMenuSelectedItemInTabAndActiveTab
  | UpdateIsDirtyInTab;
