import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { INameReference, ITableSyncStatus, db } from "core/config";
import { MessageService } from "../message-service/message.service";


@Injectable()
export class IndexedDBService {

    constructor(private messageService: MessageService) { }

    async createMinimalNameReference(projectId: string, name: string, type: string, typeResourceName: string, customerId?: string, showWarning: boolean = true, fetchTableVersion: boolean = true): Promise<{ updatedName: string, nameReference }> {
        let tableInfo;
        if (fetchTableVersion) tableInfo = await this.getTableVersion(customerId, TableNames.NameReference);
        const nameReference: Partial<INameReference> = { id: Guid.create().toString(), count: 0, type, name: name.trim().toLowerCase(), ...(tableInfo && { version: tableInfo.version }) };
        const objNameResult = await this.getNameReferenceWithMaxCount(projectId, nameReference.type, nameReference.name);
        if (objNameResult) {
            nameReference.count = objNameResult.count + 1;
            nameReference.name = objNameResult.name;
        }
        const updatedName = `${name}` + (nameReference.count > 0 ? ` (${nameReference.count})` : "");
        if (showWarning && (name != updatedName)) {
            this.messageService.showWarning(['resources.nameChangedTo', ` '${updatedName}' `, 'resources.toAvoidDuplication'], typeResourceName || `resources.name`, false);
        }
        return { updatedName, nameReference };
    }

    async getNameReferenceWithMaxCount(projectId: string, type: string, name: string) {
        let result = await db.nameReference.where({ projectId, type, name }).reverse().sortBy('count');
        if (result?.length > 0) {
            // Check count 0 exists or return null.
            if (result[result.length - 1].count > 0) {
                return null;
            }
            return result[0];
        } else {
            return null;
        }
    }

    async setToNameReferenceTable(items: any[], replace: boolean = false) {
        if (replace) await db.nameReference.clear();
        return db.nameReference.bulkPut(items);
    }

    async clearNameReferenceTable() {
        return db.nameReference.clear();
    }

    async checkForNameReference(projectId: string, name: string, type: string, referenceId: string, typeResourceName: string, customerId: string): Promise<{ updatedName: string, nameReference: INameReference, isNew: boolean }> {
        let nameReference = await db.nameReference.where({ name: name.trim().toLowerCase(), referenceId }).first();
        const tableInfo = await this.getTableVersion(customerId, TableNames.NameReference);
        if (nameReference) {
            let isNew: boolean = false;
            // Check count 0 exists or return nameReference with count 0.
            let objectNameResult = await this.getNameReferenceWithMaxCount(projectId, type, name);
            if (!objectNameResult) {
                nameReference = { name, count: 0 } as any;
                isNew = true;
            }
            const updatedName = `${name}` + (nameReference.count > 0 ? ` (${nameReference.count})` : "");
            if (name != updatedName) {
                this.messageService.showWarning(['resources.nameChangedTo', ` '${updatedName}' `, 'resources.toAvoidDuplication'], typeResourceName, false);
            }
            return { updatedName, nameReference: { ...nameReference, version: tableInfo.version }, isNew };
        } else {
            const { updatedName, nameReference } = await this.createMinimalNameReference(projectId, name, type, typeResourceName, undefined, false, false);
            return { updatedName, nameReference: { count: nameReference.count, name: nameReference.name, version: tableInfo.version } as any, isNew: true };
        }
    }

    async getTableVersion(customerId: string, tableName: string): Promise<ITableSyncStatus> {
        return db.tableSyncStatus.where({ tableName, customerId }).first();
    }

    async setTableVersion(tableSyncState: ITableSyncStatus) {
        return db.tableSyncStatus.put(tableSyncState);
    }

    async upsertNameReference(nameReference: INameReference) {
        return db.nameReference.put(nameReference);
    }

    async increaseTableVersion(customerId: string, tableName: string) {
        const result = await this.getTableVersion(customerId, tableName);
        result.version = result.version + TABLE_VERSION_INCREMENT_NUMBER;
        return this.setTableVersion(result);
    }

    async removeNameReferenceByIds(ids: string[]) {
        return db.nameReference.bulkDelete(ids);
    }
}

export const TABLE_VERSION_INCREMENT_NUMBER = 0.1;

export enum TableNames {
    NameReference = 'nameReference',
}