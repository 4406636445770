import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import * as fromActions from '../../actions';
import { VersionInformationService } from '../../services/version-information/version-information.service';

@Injectable()
export class DbTableEffects {
    constructor(
        private actions$: Actions,
        private versionInformationService: VersionInformationService,
    ) { }

    loadNameReferenceTable$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.LOAD_NAMEREFERENCE_TABLE),
        mergeMap(() => {
            return this.versionInformationService.loadNameReferenceTable().pipe(
                switchMap((data) => [
                    new fromActions.LoadNameReferenceTableSuccess({ response: data }),
                ]),
                catchError((error) => of(new fromActions.LoadNameReferenceTableFailed({ error }))),
            );
        })
    ));
}
